/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-action-sheet {
    backdrop-filter: blur(10px);
    --background: #263953 !important;
    --color: white !important;
    --button-color: white !important;
}

ion-action-sheet .action-sheet-title {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    font-size: 16px !important;
}

ion-action-sheet .action-sheet-confirm {
    background-color: #FD4159 !important;
    border-radius: 16px;
}

ion-action-sheet .action-sheet-button-inner {
    font-size: 16px;
}

// ion-action-sheet .action-sheet-confirm::after {
//     background-color: none;
// }

ion-action-sheet .action-sheet-cancel::after {
    background: #263953 !important;
    color: white !important;
}

ion-select.wide-select {
    --placeholder-color: white;
    --placeholder-opacity: 1;
    --padding-start: 20px;
    --padding-end: 20px;
    border-radius: 13px;
    width: 100%;
    background: #1E3453;
}

ion-select.wide-select::part(icon) {
    color: white;
    opacity: 1 !important;
}

ion-select.wide-select::part(text) {
    color: white;
    font-size: 16px;
}

ion-alert.select-custom-popover {
    backdrop-filter: blur(2px) !important;

    .alert-wrapper {
        background: none !important;
    }

    .alert-head {
        display: none;
    }

    .alert-message {
        display: none;
    }

    .alert-radio-button {
        // border-bottom: 0.5px solid white;
        &.ion-focused {
            background: #1E3453 !important;
        }
    }

    .alert-radio-group {
        background: #1E3453;
        color: white;
    }

    .alert-radio-label {
        color: white !important;
        font-size: 16px;
    }

    .alert-radio-inner {
        border-color: white !important;
        width: 8px !important;
        height: 16px !important;
    }

    .alert-button-group {
        color: white !important;
        background: #1E3453;

        .alert-button-inner {
            color: white;
        }
    }

    ion-select-popover {
        ion-list {
            background: none !important;

            ion-item {
                --inner-border-width: 0 0px 0.5px 0;
            }

            ion-item:last-child {
                --inner-border-width: 0 0px 0px 0 !important;
            }
            
        }
    }
}

ion-alert.select-custom-popover::part(content) {
    background: rgba(38, 57, 83, 0.9);
    border: none !important;
    // width: 75%;
    @media only screen and (max-width: 768px) {
        width: calc(calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) + -90px);
        max-width: calc(calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) - 90px);
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
        width: calc(calc(calc(8 / var(--ion-grid-columns, 12)) * 100%) + -90px);
        max-width: calc(calc(calc(8 / var(--ion-grid-columns, 12)) * 100%) - 90px);
    }
    @media only screen and (min-width: 992px) {
        width: calc(calc(calc(4 / var(--ion-grid-columns, 12)) * 100%) + -90px);
        max-width: calc(calc(calc(4 / var(--ion-grid-columns, 12)) * 100%) - 90px);
    }
}

ion-alert.select-custom-popover::part(arrow) {
    --background: rgba(38, 57, 83, 0.9);
    display: none;
}

ion-popover.select-custom-popover {
    backdrop-filter: blur(2px) !important;

    ion-select-popover {
        ion-list {
            background: none !important;

            ion-item {
                --inner-border-width: 0 0px 0.5px 0;
            }

            ion-item:last-child {
                --inner-border-width: 0 0px 0px 0 !important;
            }
            
        }
    }
}

ion-popover.select-custom-popover::part(content) {
    background: rgba(38, 57, 83, 0.9);
    border: none !important;
    // width: 75%;
    @media only screen and (max-width: 768px) {
        width: calc(calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) + -90px);
        max-width: calc(calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) - 90px);
    }
    @media only screen and (min-width: 768px) and (max-width: 992px) {
        width: calc(calc(calc(8 / var(--ion-grid-columns, 12)) * 100%) + -90px);
        max-width: calc(calc(calc(8 / var(--ion-grid-columns, 12)) * 100%) - 90px);
    }
    @media only screen and (min-width: 992px) {
        width: calc(calc(calc(4 / var(--ion-grid-columns, 12)) * 100%) + -90px);
        max-width: calc(calc(calc(4 / var(--ion-grid-columns, 12)) * 100%) - 90px);
    }
}

ion-popover.select-custom-popover::part(arrow) {
    --background: rgba(38, 57, 83, 0.9);
    display: none;
}

ion-select-popover {
    ion-list {
        --inner-border-width: 0;
    }

    ion-item {
        --background: rgba(38, 57, 83, 0.9);
        --padding-start: 20px;
        --padding-end: 20px;
        --inner-padding-end: 0;
        --background-focused-opacity: 0;
        --inner-border-width: 0;
        color: white !important;
        font-size: 16px;

        ion-label {
            color: white !important;
        }

        ion-radio {
            margin-right: 0;
        }

        ion-radio::part(mark) {
            border-color: white !important
        }

        &:hover {
            color: white !important;
        }
    }
}

ion-button.gradient-button {
    border-radius: 30px;
    --border-width: 0px !important;
    background: linear-gradient(89deg, rgba(245, 64, 87, 1) 0%, rgba(139, 122, 254, 1) 51%, rgba(67, 181, 242, 1) 95%);
}

ion-button.empty-button {
    border-radius: 38px;
    --border: 0.5px solid white;
    background: none;
}

ion-segment.wide-segment {
    --background: #1E3453;
    box-shadow: 0px 3px 8px 3px rgb(0 0 0 / 15%);
    margin-bottom: 28px;
    height: 49px;

    ion-segment-button {
        --indicator-color: #08C2FF;
        --color-checked: black;
        font-size: 16px;
        padding: 12px 0;
        --border-radius: 12px;
        margin: 0 !important;
        --color: white;
    }

    &.red {
        ion-segment-button {
            --indicator-color: #FD4159;
            --color-checked: white;
            font-size: 16px;
            padding: 12px 0;
            --border-radius: 12px;
            margin: 0 !important;
            --color: white;
        }
    }
}

ion-checkbox {
    --size: 30px;
    --checkbox-background-checked: transparent;
}

ion-checkbox::part(container) {
    border-radius: 9px;
    border: 0.5px solid #FFFFFF;
    background: var(--background) !important;
}

ion-alert.announce-alert {
    --background: #263953 !important;
    --max-width: 90%;
    backdrop-filter: blur(10px);
    --max-height: 90%;

    button.alert-button.alert-cancel-button {
        // background-color: var(--ion-color-success);
        color: white;
    }

    .announce-description {
        padding: 10px 0;
    }

    .alert-title {
        color: white !important;
        font-family: 'Gilroy-Regular' !important;
        padding: 10px;
        margin: 0;
    }

    .alert-message {
        max-height: unset !important;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 124%;
        letter-spacing: 0.005em;
        color: #EFEFEF;
        font-family: 'Gilroy-Regular' !important;
        overflow-y: auto;
    }

    // .alert-message::-webkit-scrollbar {
    //     width: 5px;
    // }
    // .alert-message::-webkit-scrollbar-track {
    //     background: #dcdcdf;
    // }
    // .alert-message::-webkit-scrollbar-thumb {
    //     background: red;
    //     border-radius: 10px;
    //     margin-right: 2px;
    // }
    // .alert-message::-webkit-scrollbar-thumb:hover {
    //     background: blueviolet;
    // }
}

// External-link-Sheet
// ion-action-sheet.external-link-sheet {
//     backdrop-filter: blur(10px);
//     --background: #000000 !important;
//     --color: white !important;
//     --button-color: white !important;
// }
// ion-action-sheet.external-link-sheet .action-sheet-container {
//     padding-left: 0 !important;
//     padding-right: 0 !important;
// }
// ion-action-sheet.external-link-sheet .action-sheet-group {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 0;
//     padding-bottom: 12px;
//     border-radius: 13px 13px 0 0;
//     padding-left: 24px;
//     padding-right: 24px;

//     &.action-sheet-group-cancel {
//         border-radius: 0 0 13px 13px;
//         padding-bottom: 27px;
//         margin-bottom: 0;

//         button {
//             border-radius: 14px !important;
//             height: 50px;
//             color: #0A84FF;
//             font-weight: 600;
//         }
//     }
// }

// ion-action-sheet.external-link-sheet .action-sheet-title {
//     padding-top: 70px !important;
//     padding-bottom: 0 !important;
//     text-align: center;
//     font-size: 34px !important;
//     line-height: 41px;
//     font-weight: bold;
//     overflow-y: auto;
// }

// ion-action-sheet.external-link-sheet .action-sheet-sub-title {
//     margin-top: 16px;
//     text-align: center;
//     font-size: 17px !important;
//     line-height: 22px;
//     font-weight: normal;
//     color: #FFFFFF;
// }

// ion-action-sheet.external-link-sheet .action-sheet-confirm {
//     background-color: #2C2C2E !important;
//     border-radius: 14px;
//     margin-top: 24px;
//     font-size: 17px;
//     line-height: 22px;
//     font-weight: 600;
//     height: 50px;
//     color: #0A84FF;
// }

// ion-action-sheet.external-link-sheet .action-sheet-button-inner {
//     font-size: 17px;
//     line-height: 22px;
// }

// ion-action-sheet.external-link-sheet .action-sheet-confirm::after {
//     background-color: none;
// }

// ion-action-sheet.external-link-sheet .action-sheet-cancel::after {
//     background: #2C2C2E !important;
//     color: #0A84FF !important;
// }


// External-link-Sheet IPAD
// ion-action-sheet.external-link-sheet-ipad {
//     backdrop-filter: blur(10px);
//     --background: #000000 !important;
//     --color: white !important;
//     --button-color: white !important;
//     --max-width: 100%;
// }
// ion-action-sheet.external-link-sheet-ipad .action-sheet-container {
//     padding-left: 0 !important;
//     padding-right: 0 !important;
//     padding-bottom: 44px;
//     padding-top: 44px;
// }
// ion-action-sheet.external-link-sheet-ipad .action-sheet-group {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 0;
//     padding-bottom: 12px;
//     border-radius: 13px 13px 0 0;
//     padding-left: 88px;
//     padding-right: 88px;
//     align-items: center;
//     flex-grow: 1;
//     justify-content: space-between;

//     &.action-sheet-group-cancel {
//         border-radius: 0 0 13px 13px;
//         padding-bottom: 40px;
//         margin-bottom: 0;
//         padding-left: 132px;
//         padding-right: 132px;
//         flex-grow: 0;

//         button {
//             border-radius: 14px !important;
//             height: 50px;
//             color: #0A84FF;
//             font-weight: 600;
//             max-width: 360px;
//         }
//     }
// }

// ion-action-sheet.external-link-sheet-ipad .action-sheet-title {
//     padding-top: 88px !important;
//     padding-bottom: 0 !important;
//     text-align: center;
//     font-size: 34px !important;
//     line-height: 41px;
//     font-weight: bold;
//     overflow-y: auto;
// }

// ion-action-sheet.external-link-sheet-ipad .action-sheet-sub-title {
//     margin-top: 15px;
//     text-align: center;
//     font-size: 17px !important;
//     line-height: 22px;
//     font-weight: normal;
//     color: #FFFFFF;
// }

// ion-action-sheet.external-link-sheet-ipad .action-sheet-learnmore {
//     background-color: red;
// }

// ion-action-sheet.external-link-sheet-ipad .action-sheet-learnmore .action-sheet-button-inner {
//     width: fit-content;
//     font-size: 17px !important;
//     line-height: 22px;
//     color: #0A84FF;
//     font-weight: normal;
// }

// ion-action-sheet.external-link-sheet-ipad .action-sheet-confirm {
//     background-color: #2C2C2E !important;
//     border-radius: 14px;
//     margin-top: 24px;
//     font-size: 17px;
//     line-height: 22px;
//     font-weight: 600;
//     height: 50px;
//     color: #0A84FF;
//     margin-right: 44px;
//     margin-left: 44px;
//     max-width: 360px;
//     width: -webkit-fill-available;
// }

// ion-action-sheet.external-link-sheet-ipad .action-sheet-button-inner {
//     font-size: 17px;
//     line-height: 22px;
// }

// ion-action-sheet.external-link-sheet-ipad .action-sheet-cancel::after {
//     background: #2C2C2E !important;
//     color: #0A84FF !important;
// }